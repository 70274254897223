<!--
 * @Author: wxy
 * @Date: 2022-07-05 14:11:41
 * @LastEditTime: 2022-10-24 09:23:48
 * @Description: 服务咨询管理
-->
<template>
  <div class="serverConsultingManagement">
    <ds-header title="服务咨询管理"></ds-header>
    <a-form layout="inline" style="margin-bottom: 10px">
      <a-form-item label="所属项目：">
        <!-- <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.projectId"
        >
          <a-select-option value=""> 全部项目 </a-select-option>
          <a-select-option v-for="item in projectName" :key="item.projectId" :value="item.projectId">
            {{ item.projectName }}
          </a-select-option>
        </a-select> -->
        <!-- <el-cascader v-model="searchForm.projectId" style="width: 200px"
              :options="projectName"
              :props="{emitPath:false,checkStrictly: true , label: 'project_name', children: 'child', value: 'id' }" size="small"
              clearable></el-cascader> -->
        <a-cascader expandTrigger="hover" style="width: 100%;" v-model="searchForm.projectId"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode" :options="projectName"
          :fieldNames="{ label: 'project_name', children: 'child', value: 'id' }"
          placeholder="请选择供应商适用项目" />
      </a-form-item>
      <a-form-item label="状态">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.serverStatus"
        >
          <a-select-option value=""> 全部状态 </a-select-option>
          <a-select-option v-for="item in serverStatus" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="处理人">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          style="width: 200px"
          placeholder="请选择"
          v-model="searchForm.workUserId"
        >
          <a-select-option value=""> 全部处理人 </a-select-option>
          <a-select-option v-for="item in workerList" :key="item.userId" :value="item.userId">
            {{ item.userName }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <span>
        <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm" icon="redo">重置</a-button>
      </span>
    </a-form>

    <a-table
      :rowKey="(record, index) => index"
      :columns="table.columns"
      :data-source="table.tableData"
      :pagination="table.pagination.total ? table.pagination : false"
      @change="pageChange"
      bordered
      :scroll="{ x: 1500 }"
    >
      <div slot="consultingTitle" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.consultingTitle }}</span>
          </template>
          <div
            :style="{
              maxWidth: '180px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.consultingTitle }}
          </div>
        </a-tooltip>
      </div>
      <div slot="contactName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.contactName }}</span>
          </template>
          <div
            :style="{
              maxWidth: '150px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.contactName }}
          </div>
        </a-tooltip>
      </div>
      <div slot="enterpriseName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.enterpriseName }}</span>
          </template>
          <div
            :style="{
              maxWidth: '200px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.enterpriseName }}
          </div>
        </a-tooltip>
      </div>
      <div slot="projectName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.projectName }}</span>
          </template>
          <div
            :style="{
              maxWidth: '220px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.projectName }}
          </div>
        </a-tooltip>
      </div>
      <div slot="supplierNames" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.supplierNames }}</span>
          </template>
          <div
            :style="{
              maxWidth: '220px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.supplierNames }}
          </div>
        </a-tooltip>
      </div>
      <div slot="workUserIdShow" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span>{{ text.workUserIdShow }}</span>
          </template>
          <div
            :style="{
              maxWidth: '150px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }"
          >
            {{ text.workUserIdShow }}
          </div>
        </a-tooltip>
      </div>
      <span slot="action" slot-scope="text, record" class="action">
        <a-button type="link" @click="toDetail(record)" class="btn"> 详情</a-button>
        <a-button type="link" @click="serverRecord(record)" v-if="record.serverStatus !== 1" class="btn">
          跟踪记录
        </a-button>
        <a-button type="link" @click="accept(record)" v-if="record.serverStatus === 1" class="btn"> 受理咨询 </a-button>
        <a-button type="link" @click="finish(record)" v-if="record.serverStatus === 2" class="btn"> 结束咨询 </a-button>
      </span>
    </a-table>
  </div>
</template>

<script>
import * as api from "@/api/entrepreneurClubManagement/serverManagement";
export default {
  name: "serverConsultingManagement",
  data() {
    return {
      // 筛选条件
      searchForm: {
        // 所属项目
        projectId: [],
        //处理状态
        serverStatus: "",
        // 处理人
        workUserId: "",
      },
      // 受理状态
      serverStatus: [
        {
          value: 1,
          label: "待受理",
        },
        {
          value: 2,
          label: "沟通中",
        },
        {
          value: 3,
          label: "已结束",
        },
      ],
      // 处理人
      workerList: [],
      // 项目
      projectName: [],
      table: {
        columns: [
          {
            title: "咨询标题",
            key: "consultingTitle",
            scopedSlots: { customRender: "consultingTitle" },
            width: 180,
          },
          {
            title: "服务类型",
            dataIndex: "serverTypeName",
            key: "serverTypeName",
            width: 180,
          },
          {
            title: "联系人姓名",
            key: "contactName",
            scopedSlots: { customRender: "contactName" },
            width: 150,
          },
          {
            title: "联系人电话",
            dataIndex: "contactPhone",
            key: "contactPhone",
            width: 180,
          },
          {
            title: "联系人所在公司",
            key: "enterpriseName",
            scopedSlots: { customRender: "enterpriseName" },
            width: 200,
          },
          {
            title: "所属项目",
            key: "projectName",
            scopedSlots: { customRender: "projectName" },
            width: 220,
          },
          {
            title: "供应商选择",
            key: "supplierNames",
            scopedSlots: { customRender: "supplierNames" },
            width: 220,
          },
          {
            title: "咨询提交时间",
            dataIndex: "gmtCreate",
            key: "gmtCreate",
            width: 180,
          },
          {
            title: "状态",
            dataIndex: "serverStatus",
            key: "serverStatus",
            width: 120,
            customRender(text) {
              return text == 1 ? "待受理" : text == 2 ? "沟通中" : "已结束";
            },
          },
          {
            title: "处理人",
            key: "workUserIdShow",
            scopedSlots: { customRender: "workUserIdShow" },
            width: 150,
          },
          {
            title: "操作",
            key: "action",
            width: 250,
            className: "action-ql-list",
            scopedSlots: { customRender: "action" },
          },
        ],
        tableData: [],
        pagination: {
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
          current: 1,
          pageSize: 10,
          total: 0,
          showQuickJumper: true,
          showTotal: (total) => {
            return `共 ${total} 条`;
          },
        },
      },
    };
  },
  // 进入路由添加缓存
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch("operationCachedRoutes/updateAliveRoutes", ["serverConsultingManagement"]);
    });
  },
  // 离开清除缓存
  beforeRouteLeave(to, from, next) {
    if (
      to.path !== "/entrepreneurClubManagement/serverManagement/serverConsultingManagement/detail" &&
      to.path !== "/entrepreneurClubManagement/serverManagement/serverConsultingManagement/serverRecord"
    ) {
      this.$store.dispatch("operationCachedRoutes/updateAliveRoutes", []);
    }
    next();
  },
  mounted() {
    // 获取项目
    this.getProject();
    // 获取列表数据
    this.getList();
    // 获取处理人
    this.getWorkerList();
  },
  methods: {
    // 查询
    search() {
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 重置
    resetSearchForm() {
      this.searchForm = {
        // 所属项目
        projectId: [],
        //处理状态
        serverStatus: "",
        // 处理人
        workUserId: "",
      };
      this.table.pagination.current = 1;
      this.table.pagination.pageSize = 10;
      this.getList();
    },
    // 获取列表
    async getList() {
      try {
        const data = {
          page: this.table.pagination.current,
          size: this.table.pagination.pageSize,
          ...this.searchForm,
          projectId:this.searchForm.projectId.length?this.searchForm.projectId[this.searchForm.projectId.length-1]:''
        };
        const res = await api.serverConsultationList(data);
        if (res.code === "200") {
          this.table.tableData = res.data.list;
          this.table.pagination.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },

    // 跟踪记录
    serverRecord(record) {
      this.$router.push({
        path: "/entrepreneurClubManagement/serverManagement/serverConsultingManagement/serverRecord",
        query: {
          id: record.serverConsultingId,
        },
      });
    },
    // 详情
    toDetail(record) {
      this.$router.push({
        path: "/entrepreneurClubManagement/serverManagement/serverConsultingManagement/detail",
        query: {
          id: record.serverConsultingId,
        },
      });
    },
    // 切换分页
    pageChange(option) {
      this.table.pagination.current = option.current;
      this.table.pagination.pageSize = option.pageSize;
      this.getList();
    },
    // 受理咨询
    accept(record) {
      const _this = this;
      _this.$confirm({
        title: "确认受理该咨询",
        content: "",
        async onOk() {
          try {
            const res = await api.serverConsultationStatus(record.serverConsultingId, 2);
            if (res.code === "200") {
              _this.$message.success("操作成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 结束咨询
    finish(record) {
      const _this = this;
      _this.$confirm({
        title: "确认结束该咨询",
        content: "",
        async onOk() {
          try {
            const res = await api.serverConsultationStatus(record.serverConsultingId, 3);
            if (res.code === "200") {
              _this.$message.success("操作成功");
              _this.getList();
            } else {
              _this.$message.error(res.msg);
            }
          } catch (error) {}
        },
        onCancel() {},
      });
    },
    // 获取所属项目
    async getProject() {
      try {
        const res = await api.getShunyiAllProject();
        if (res.code === "200") {
          this.projectName = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (error) {}
    },
    // 获取处理人
    async getWorkerList() {
      try {
        const res = await api.getServerUserList();
        if (res.code === "200") {
          this.workerList = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.btn {
  padding: 4px;
}
</style>